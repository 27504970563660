<template>
    <v-card elevation="1" outlined :loading="login.loadingLogin" min-height="302">
        <template slot="progress">
            <v-progress-linear
                :color="parameters.secondaryColor"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>
        <v-card-title>Já sou cadastrado</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    v-model="login.user.cf1cliente"
                    :rules="cnpjRules"
                    label="CNPJ"
                    required
                    dense
                    flat
                    solo
                    outlined
                    prepend-icon="mdi-account"
                    :color="parameters.primaryColor"
                    v-mask="'##.###.###/####-##'"
                ></v-text-field>

                <v-text-field
                    v-model="login.user.cf1senha"
                    :rules="passwordRules"
                    label="Senha"
                    type="password"
                    required
                    dense
                    flat
                    solo
                    outlined
                    prepend-icon="mdi-lock"
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-btn
                    class="ma-1 caption text-none"
                    :color="parameters.secondaryColor"
                    plain
                    @click="SendRecoverPass"
                    dark
                    small
                    left
                >
                    <v-icon small class="pr-2">mdi-lock-reset</v-icon>
                    Esqueci minha senha
                </v-btn>
                <DialogForgotPassword />
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
            <v-btn
                :disabled="!valid || login.loadingLogin"
                :color="parameters.secondaryColor"
                class="white--text"
                width="80%"
                @click="validate"
            >
                Entrar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
const DialogForgotPassword = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/login/DialogForgotPassword.vue"
    );
export default {
    data: () => ({
        valid: true,
        cnpjRules: [
            (v) => !!v || "CNPJ é obrigatório",
            (v) => (v && v.replace(/[^\d]/g, "").length == 14) || "CNPJ inválido",
        ],
        passwordRules: [(v) => !!v || "A senha é obrigatória"],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch("login/auth")
            }
        },
        SendRecoverPass(){
            this.login.showForgotPass = true
        },
    },
    components:{
        DialogForgotPassword
    }
};
</script>

<style>
</style>